import {
  GitHubIcon,
  LinkedInIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../../core/icons'

const headerItems = {
  upper: [],
  lower: [
    {
      name: 'Why Approov',
      offset: 'translate-small',
      items: [
        {
          name: 'RASP',
          showIndex: false,
          href: '/mobile-app-security/rasp',
          
        },
        {
          name: 'App\nAttestation',
          href: '/mobile-app-security/rasp/app-attestation',
        },
        {
          name: 'Runtime Secrets\n Protection',
          href: '/mobile-app-security/rasp/runtime-secrets',
        },
        {
          name: 'Real Time\nThreat Intelligence',
          href: '/mobile-app-security/rasp/realtime-threat-intelligence',
        },
        {
          name: 'API\nSecurity',
          href: '/mobile-app-security/rasp/api-security',
        },
        {
          name: 'Dynamic Certificate\nPinning',
          href: '/mobile-app-security/rasp/dynamic-cert-pinning',
        },
        {
          name: 'App\nShielding',
          href: '/mobile-app-security/rasp/app-shielding',
        },
      ],
    },
    { name: 'Key Threats', 
      offset: 'translate-small',
      items: [
        {
          name: 'Key Threats',
          showIndex: false,
          href: '/threats',
        },
        {
          name: `Threats\n Detected`,
          href: '/threats/real-time',
          blank: true
        },
        {
          name: 'Threats by\nCategory',
          href: '/threats/category',
          blank: true
        },
        {
          name: 'Android Threats\nby Category',
          href: '/threats/android-category',
          blank: true
        },
        {
          name: 'iOS Threats\nby Category',
          href: '/threats/ios-category',
          blank: true
        }
      ],

    },
    { name: 'Pricing', href: '/pricing' },
    {
      name: 'Industries',
      offset: 'translate-small',
      items: [
        {
          name: 'Mobile Finance',
          showIndex: false,
          href: '/industry/mobile-finance',
        },
        {
          name: 'Healthcare',
          href: '/market/mhealth',
        },
        {
          name: 'Mobility',
          href: '/market/mobility',
        },
        {
          name: 'Retail',
          href: '/market/retail',
        },
        {
          name: 'Mobile Gaming &\nGambling',
          href: '/industry/mobile-gaming-and-gambling',
        },
      ],
    },
    { name: 'Testimonials', href: '/customer' },
    {
      name: 'Resources',
      offset: 'translate-large',
      items: [
        { 
          name: 'Blog',
          showIndex: false,
          href: 'https://approov.io/blog' },
        {
          name: 'Videos &\nWhitepapers',
          href: '/resource',
        },
        {
          name: 'Quickstarts',
          href: '/resource/quickstarts/',
        },
        {
          name: 'Cert Pinning\nGenerator',
          href: '/tools/static-pinning',
        },
        {
          name: 'Partners &\nIntegrations',
          href: 'https://approov.io/info/partners',
          blank: false,
        },
        {
          name: 'Developer\nDocs',
          href: '/docs',
        },
        {
          name: 'Knowledge\nBase',
          href: 'https://approov.io/knowledge',
          blank: false,
        },
        {
          name: 'Podcasts',
          href: 'https://approov.io/info/podcast',
          blank: false,
        },
      ],
    },
  ],
}

const footerItems = {
  left: {
    headquarters: [
      {
        name: 'US Headquarters',
        items: [
          '165 University Avenue',
          'Suite 200',
          'Palo Alto, CA 94301 USA',
          {
            'name': 'P',
            'item': '+1 (650) 322-5300'
          }
        ],
      },
      {
        name: 'UK Headquarters',
        items: [
          '181 The Pleasance',
          'Edinburgh, Midlothian',
          'EH8 9RU, United Kingdom',
          {
            'name': 'P',
            'item': '+44 0131 655 1500'
          }
        ],
      },
    ],
    contactInfo: [
      { name: 'US', item: '+1 (650) 322-5300‬' },
      // { name: 'UK', item: '+44 131 655 1500'},
      // { name: "General", item: "info@approov.io" },
    ],
    links: [
      { name: 'Contact', href: 'https://approov.io/info/contact' },
      { name: 'Company', href: 'https://approov.io/info/company' },
      { name: 'News', href: 'https://approov.io/info/news' },
      { name: 'Events', href: 'https://approov.io/info/events' },
      { name: 'Blog', href: 'https://approov.io/blog/' },
      { name: 'Knowledge Base', href: 'https://approov.io/knowledge' },
      { name: 'Careers', href: 'https://approov.io/info/careers' },
      { name: 'Support', href: 'https://approov.io/info/technical-support' }
    ],
  },
  right: {
    links: [
      {
        name: 'Site Map',
        href: '/sitemap',
        items: [
          { name: 'Product', href: '/product' },
          { name: 'Threats', href: '/threats' },
          { name: 'Free Trial', href: '/signup' },
          { name: 'Pricing', href: '/pricing' },
          {
            name: 'Payment Portal',
            href: 'https://approov.chargebeeportal.com/portal/login',
          },
          { name: 'Patents', href: 'https://approov.io/info/patents' },
        ],
      },
      {
        name: 'Industries',
        items: [
          { name: 'Mobile Finance', href: '/industry/mobile-finance/' },
          { name: 'Healthcare', href: '/market/mhealth' },
          { name: 'Mobility', href: '/market/mobility' },
          { name: 'Retail', href: '/market/retail' },
          { name: 'Mobile Gaming & Gambling', href: '/industry/mobile-gaming-and-gambling/' },
        ],
      },
      {
        name: 'Resources',
        items: [
          {
            name: 'Videos & Whitepapers',
            href: '/resource/#videos',
          },
          { name: 'Quickstarts', href: '/resource/quickstarts' },
          { name: 'Cert Pinning Generator', href: '/tools/static-pinning' },
          { name: 'Partners and Integrations', href: 'https://approov.io/info/partners' },
          { name: 'Developer Docs', href: 'https://approov.io/docs' },
          { name: 'Podcasts', href: 'https://approov.io/info/podcast' },
        ],
      },
    ],
  },
  social: [
    { name: 'GitHub', icon: GitHubIcon, href: 'https://github.com/approov' },
    {
      name: 'LinkedIn',
      icon: LinkedInIcon,
      href: 'https://www.linkedin.com/company/criticalblue/',
    },
    {
      name: 'Twitter',
      icon: TwitterIcon,
      href: 'https://www.twitter.com/@approov_io',
    },
    {
      name: 'Youtube',
      icon: YoutubeIcon,
      href: 'https://www.youtube.com/@approovmobilesecurity',
    },
  ],
}

export { headerItems, footerItems }
